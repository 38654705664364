import axios from "axios";
import { ReactNode } from "react";
// import { encryptObj } from '../utils/crypto';

/**
 * @description
 *  wtgAxios is an axios instance that is used to make requests to the NextJS API Route
 *  wtgSSAxios is an axios instance that is used to make requests to the WTG API
 */

export const threeXPAxiosBaseURL = "/api/v2";
export const threeXPAxios = axios.create({ baseURL: threeXPAxiosBaseURL }); // Client Side Instance (Same Origin)

/**
 * @description
 */
threeXPAxios.defaults.baseURL =
  process.env.REACT_APP_API_BASEURL + threeXPAxiosBaseURL;

export const setAuthorizationHeader = (authToken: string): void => {
  threeXPAxios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

export const removeAuthorizationHeader = () => {
  delete threeXPAxios.defaults.headers.common["Authorization"];
};

/**
 * @description set a single header key
 */
export const setHeader = (key: string, value: string): void => {
  threeXPAxios.defaults.headers.common[key] = value;
};

/**
 * @description remove header specifiy by kesy
 */
export const removeHeaderKeys = (keys: string | Array<string>): void => {
  if (Array.isArray(keys)) {
    return keys.forEach(
      (key) => delete threeXPAxios.defaults.headers.common[key],
    );
  }
  delete threeXPAxios.defaults.headers.common[keys];
};

export const ConnectionProvider = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

setAuthorizationHeader(process.env.REACT_APP_API_KEY || "");
