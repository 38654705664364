import { threeXPAxios } from '../contexts/ConnectionContext';

type TwitterAuthObj = {
  id: string;
  name: string;
  username: string;
  profileImageUrl: string;
  followers: number;
  createdAt: Date;
  token: string;
  tokenSecret: string;
};

// export type Suspected = "Chan" | "Kla" | "Pom" | "Khom" | "Kraam";

export type AuthMeResponse = {
  _id: string;
  account: any;
  walletAddress: string;
  createdAt: string;
  updatedAt: string;
  chats: [];
  games: [];
  twitter?: TwitterAuthObj;
  raffle?: {
    // answer: Suspected | undefined;
  };
};

/**
 * @description this registers wallet address and response 201 if success,
 * if the address exists in the db, it will return 409 conflict instead
 */
export const postAuth = async (
  walletAddress: string,
): Promise<AuthMeResponse> => {
  const user = await getAccount(walletAddress);
  if (user.account) return user;
  const endpoint = '/auth/register';
  return threeXPAxios
    .post(endpoint, { type: 'user', walletAddress })
    .then(({ data }) => data);
};

export const getAccount = async (
  walletAddress: string,
): Promise<AuthMeResponse> => {
  const endpoint = `/accounts/${walletAddress}`;

  return threeXPAxios.get(endpoint).then(({ data }) => data);
};

export const getAuthMe = async (): Promise<AuthMeResponse> => {
  const endpoint = '/auth/nm/me';

  return threeXPAxios.get(endpoint).then(({ data }) => data);
};

export const revokeTwitter = async (): Promise<void> => {
  const endpoint = '/auth/nm/twitter';

  return threeXPAxios.delete(endpoint).then(({ data }) => data);
};
