// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAccount } from "wagmi";
import { useAccountModal } from "@rainbow-me/rainbowkit";

import { postAuth } from "../../api/auth";

type TwitterAuthObj = {
  id: string;
  name: string;
  username: string;
  profileImageUrl: string;
  followers: number;
  createdAt: Date;
  token: string;
  tokenSecret: string;
};

export type UserObj = {
  _id: string;
  account: any;
  walletAddress?: string;
  twitter?: TwitterAuthObj;
  referralCode?: string;
};

interface AuthContextProps {
  isLoading: boolean;
  error?: Error;
  userObj?: UserObj;
  // -- exported functions
  updateUserObj?: (account: string, updatedFields: UserObj) => void;
  getTwitterInfoWrapper?: () => Promise<
    | {
        twitterUsername: string;
        twitterImage: string;
      }
    | undefined
  >;
  // -- setters
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setError?: Dispatch<SetStateAction<Error | undefined>>;
}

export const AuthContext = createContext<AuthContextProps>({
  isLoading: true,
  error: undefined,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userObj, setUserObj] = useState<UserObj>();
  const [error, setError] = useState<Error | undefined>(undefined);

  const { address: account, isConnected, isDisconnected } = useAccount();
  const { openAccountModal } = useAccountModal();

  // --- CALLBACKS
  /**
   * @description userObj state is updated via this function
   */
  const updateUserObj = useCallback(
    (account: string, updatedFields: UserObj) => {
      if (!account) return;

      setUserObj?.((p) => {
        const updatedUserObj: Partial<UserObj> = {
          ...(p || {}),
          ...updatedFields,
          walletAddress: account,
        };

        return updatedUserObj;
      });
    },
    [],
  );

  useEffect(() => {
    (async () => {
      if (openAccountModal && isConnected && account) {
        const postAuthResult = await postAuth(account);
        updateUserObj(account, postAuthResult);
        setIsLoading(false);
      }
    })();
  }, [openAccountModal, updateUserObj, isConnected, account]);

  useEffect(() => {
    if (isDisconnected) {
      setUserObj(undefined);
    }
  }, [isDisconnected, setUserObj]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userObj,
        error,
        // -- exported functions
        updateUserObj,
        // -- setters
        setIsLoading,
        setError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
