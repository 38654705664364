import { DetailedHTMLProps, HTMLAttributes } from "react";

const Icon = (props: IconProps) => {
  const classes = `${props.name} ${props.className}`;

  return <i {...props} className={classes} />;
};

export default Icon;

interface IconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  name:
    | "icon-refresh-ccw-01"
    | "icon-annotation-info"
    | "icon-x"
    | "icon-whale-alphie"
    | "icon-whale-twitter"
    | "icon-whale-average"
    | "icon-whale-buy-now-3d"
    | "icon-whale-buy-now-cart"
    | "icon-whale-buy-now-text"
    | "icon-whale-card-list"
    | "icon-whale-down"
    | "icon-whale-eth"
    | "icon-whale-fast"
    | "icon-whale-high"
    | "icon-whale-ninja"
    | "icon-whale-oil-gas"
    | "icon-whale-slow"
    | "icon-whale-star"
    | "icon-whale-super-fast"
    | "icon-whale-up"
    | "icon-whale-wallet"
    | "icon-pallet-in"
    | "icon-pallet-out"
    | "icon-pallet"
    | "icon-product"
    | "icon-beer-bottle"
    | "icon-return-pack"
    | "icon-increase-pack"
    | "icon-barcode-scan"
    | "icon-barcode"
    | "icon-receipt1"
    | "icon-cloud-01"
    | "icon-cloud-02"
    | "icon-cloud-03"
    | "icon-cloud-lightning"
    | "icon-cloud-moon"
    | "icon-cloud-off"
    | "icon-cloud-raining-01"
    | "icon-cloud-raining-02"
    | "icon-cloud-raining-03"
    | "icon-cloud-raining-04"
    | "icon-cloud-raining-05"
    | "icon-cloud-raining-06"
    | "icon-cloud-snowing-01"
    | "icon-cloud-snowing-02"
    | "icon-cloud-sun-01"
    | "icon-cloud-sun-02"
    | "icon-cloud-sun-03"
    | "icon-droplets-01"
    | "icon-droplets-02"
    | "icon-droplets-03"
    | "icon-hurricane-01"
    | "icon-hurricane-02"
    | "icon-hurricane-03"
    | "icon-lightning-01"
    | "icon-lightning-02"
    | "icon-moon-01"
    | "icon-moon-02"
    | "icon-moon-eclipse"
    | "icon-moon-star"
    | "icon-snowflake-01"
    | "icon-snowflake-02"
    | "icon-stars-01"
    | "icon-stars-02"
    | "icon-stars-03"
    | "icon-sun"
    | "icon-sun-setting-01"
    | "icon-sun-setting-02"
    | "icon-sun-setting-03"
    | "icon-sunrise"
    | "icon-sunset"
    | "icon-thermometer-01"
    | "icon-thermometer-02"
    | "icon-thermometer-03"
    | "icon-thermometer-cold"
    | "icon-thermometer-warm"
    | "icon-umbrella-01"
    | "icon-umbrella-02"
    | "icon-umbrella-03"
    | "icon-waves"
    | "icon-wind-01"
    | "icon-wind-02"
    | "icon-wind-03"
    | "icon-camera-01"
    | "icon-camera-02"
    | "icon-camera-03"
    | "icon-camera-lens"
    | "icon-camera-off"
    | "icon-camera-plus"
    | "icon-colors1"
    | "icon-flash"
    | "icon-flash-off"
    | "icon-image-01"
    | "icon-image-02"
    | "icon-image-011"
    | "icon-image-04"
    | "icon-image-05"
    | "icon-image-check"
    | "icon-image-down"
    | "icon-image-left"
    | "icon-image-plus"
    | "icon-image-right"
    | "icon-image-up"
    | "icon-image-user"
    | "icon-image-user-check"
    | "icon-image-user-down"
    | "icon-image-user-left"
    | "icon-image-user-plus"
    | "icon-image-user-right"
    | "icon-image-user-up"
    | "icon-image-user-x"
    | "icon-image-x"
    | "icon-alarm-clock-off"
    | "icon-alarm-clock"
    | "icon-alarm-clock-check"
    | "icon-alarm-clock-minus"
    | "icon-alarm-clock-plus"
    | "icon-calendar"
    | "icon-calendar-check-01"
    | "icon-calendar-check-02"
    | "icon-calendar-date"
    | "icon-calendar-heart-01"
    | "icon-calendar-heart-02"
    | "icon-calendar-minus-01"
    | "icon-calendar-minus-02"
    | "icon-calendar-plus-01"
    | "icon-calendar-plus-02"
    | "icon-clock"
    | "icon-clock-check"
    | "icon-clock-fast-forward"
    | "icon-clock-plus"
    | "icon-clock-rewind"
    | "icon-clock-snooze"
    | "icon-clock-stopwatch"
    | "icon-hourglass-01"
    | "icon-hourglass-02"
    | "icon-hourglass-03"
    | "icon-clock-refresh"
    | "icon-watch-circle"
    | "icon-watch-square"
    | "icon-alert-circle"
    | "icon-alert-hexagon"
    | "icon-alert-octagon"
    | "icon-alert-square"
    | "icon-alert-triangle"
    | "icon-announcement-03"
    | "icon-announcement-01"
    | "icon-announcement-02"
    | "icon-bell-01"
    | "icon-bell-02"
    | "icon-bell-03"
    | "icon-bell-04"
    | "icon-bell-minus"
    | "icon-bell-off-01"
    | "icon-bell-off-02"
    | "icon-bell-off-03"
    | "icon-bell-plus"
    | "icon-bell-ringing-01"
    | "icon-bell-ringing-02"
    | "icon-bell-ringing-03"
    | "icon-bell-ringing-04"
    | "icon-notification-box"
    | "icon-message-notification-square1"
    | "icon-notification-text"
    | "icon-thumbs-down"
    | "icon-thumbs-up"
    | "icon-circle"
    | "icon-cube-01"
    | "icon-cube-03"
    | "icon-cube-02"
    | "icon-cube-04"
    | "icon-cube-outline"
    | "icon-dice-1"
    | "icon-dice-2"
    | "icon-dice-4"
    | "icon-dice-3"
    | "icon-dice-5"
    | "icon-dice-6"
    | "icon-hexagon-01"
    | "icon-hexagon-02"
    | "icon-octagon"
    | "icon-pentagon"
    | "icon-square"
    | "icon-star-01"
    | "icon-star-02"
    | "icon-star-03"
    | "icon-star-04"
    | "icon-star-05"
    | "icon-star-06"
    | "icon-star-07"
    | "icon-triangle"
    | "icon-brackets"
    | "icon-brackets-check"
    | "icon-brackets-ellipses"
    | "icon-brackets-minus"
    | "icon-brackets-plus"
    | "icon-brackets-slash"
    | "icon-brackets-x"
    | "icon-browser"
    | "icon-code-01"
    | "icon-code-02"
    | "icon-code-browser"
    | "icon-code-circle-01"
    | "icon-code-circle-02"
    | "icon-code-circle-03"
    | "icon-code-square-01"
    | "icon-code-square-02"
    | "icon-codepen"
    | "icon-container"
    | "icon-cpu-chip-01"
    | "icon-cpu-chip-02"
    | "icon-data"
    | "icon-database-01"
    | "icon-database-02"
    | "icon-database-03"
    | "icon-dataflow-01"
    | "icon-dataflow-02"
    | "icon-dataflow-03"
    | "icon-dataflow-04"
    | "icon-file-code-01"
    | "icon-file-code-02"
    | "icon-folder-code"
    | "icon-git-branch-01"
    | "icon-git-branch-02"
    | "icon-git-commit"
    | "icon-git-merge"
    | "icon-git-pull-request"
    | "icon-package"
    | "icon-package-minus"
    | "icon-package-check"
    | "icon-package-plus"
    | "icon-package-search"
    | "icon-package-x"
    | "icon-puzzle-piece"
    | "icon-puzzle-piece-02"
    | "icon-qr-code-01"
    | "icon-qr-code-02"
    | "icon-server-01"
    | "icon-server-02"
    | "icon-server-03"
    | "icon-server-04"
    | "icon-server-05"
    | "icon-server-06"
    | "icon-terminal"
    | "icon-terminal-browser"
    | "icon-terminal-circle"
    | "icon-terminal-square"
    | "icon-variable"
    | "icon-align-bottom-01"
    | "icon-align-bottom-02"
    | "icon-align-horizontal-centre-01"
    | "icon-align-horizontal-centre-02"
    | "icon-align-left-01"
    | "icon-align-left-02"
    | "icon-align-right-01"
    | "icon-align-right-02"
    | "icon-align-top-arrow-01"
    | "icon-align-top-arrow-02"
    | "icon-align-vertical-center-01"
    | "icon-align-vertical-center-02"
    | "icon-grid"
    | "icon-columns-02"
    | "icon-columns-03"
    | "icon-distribute-spacing-horizontal"
    | "icon-distribute-spacing-vertical"
    | "icon-divider"
    | "icon-flex-align-bottom"
    | "icon-flex-align-left"
    | "icon-flex-align-right"
    | "icon-flex-align-top"
    | "icon-grid-01"
    | "icon-grid-02"
    | "icon-grid-03"
    | "icon-grid-dots-blank"
    | "icon-grid-dots-bottom"
    | "icon-grid-dots-horizontal-center"
    | "icon-grid-dots-left"
    | "icon-grid-dots-outer"
    | "icon-grid-dots-right"
    | "icon-grid-dots-top"
    | "icon-grid-dots-vertical-center"
    | "icon-intersect-circle"
    | "icon-intersect-square"
    | "icon-layer-single"
    | "icon-layers-three-01"
    | "icon-layers-three-02"
    | "icon-layers-two-01"
    | "icon-layers-two-02"
    | "icon-layout-alt-01"
    | "icon-layout-alt-02"
    | "icon-layout-alt-03"
    | "icon-layout-alt-04"
    | "icon-layout-bottom"
    | "icon-layout-grid-01"
    | "icon-layout-grid-02"
    | "icon-layout-left"
    | "icon-layout-right"
    | "icon-layout-top"
    | "icon-list"
    | "icon-maximize-01"
    | "icon-maximize-02"
    | "icon-minimize-01"
    | "icon-minimize-02"
    | "icon-rows-01"
    | "icon-rows-02"
    | "icon-rows-03"
    | "icon-spacing-height-01"
    | "icon-spacing-height-02"
    | "icon-spacing-width-01"
    | "icon-spacing-width-02"
    | "icon-table"
    | "icon-face-content"
    | "icon-face-frown"
    | "icon-face-happy"
    | "icon-face-neutral"
    | "icon-face-sad"
    | "icon-face-smile"
    | "icon-face-wink"
    | "icon-user-01"
    | "icon-user-02"
    | "icon-users-03"
    | "icon-user-03"
    | "icon-users-02"
    | "icon-user-check-01"
    | "icon-user-check-02"
    | "icon-user-circle"
    | "icon-user-down-01"
    | "icon-user-down-02"
    | "icon-user-edit"
    | "icon-user-left-01"
    | "icon-user-left-02"
    | "icon-user-minus-01"
    | "icon-user-minus-02"
    | "icon-user-plus-01"
    | "icon-user-plus-02"
    | "icon-user-right-01"
    | "icon-user-right-02"
    | "icon-user-square"
    | "icon-user-up-01"
    | "icon-user-up-02"
    | "icon-user-x-01"
    | "icon-user-x-02"
    | "icon-users-01"
    | "icon-users-check"
    | "icon-users-down"
    | "icon-users-edit"
    | "icon-users-left"
    | "icon-users-minus"
    | "icon-users-plus"
    | "icon-users-right"
    | "icon-users-up"
    | "icon-users-x"
    | "icon-annotation-dots"
    | "icon-annotation"
    | "icon-annotation-alert"
    | "icon-annotation-check"
    | "icon-annotation-heart"
    | "icon-annotation-info"
    | "icon-annotation-plus"
    | "icon-annotation-question"
    | "icon-annotation-x"
    | "icon-inbox-01"
    | "icon-inbox-02"
    | "icon-mail-01"
    | "icon-mail-02"
    | "icon-mail-03"
    | "icon-mail-04"
    | "icon-mail-05"
    | "icon-message-alert-circle"
    | "icon-message-alert-square"
    | "icon-message-chat-circle"
    | "icon-message-chat-square"
    | "icon-message-check-circle"
    | "icon-message-check-square"
    | "icon-message-circle-01"
    | "icon-message-circle-02"
    | "icon-message-dots-circle"
    | "icon-message-dots-square"
    | "icon-message-heart-circle"
    | "icon-message-heart-square"
    | "icon-message-notification-circle"
    | "icon-message-notification-square"
    | "icon-message-plus-circle"
    | "icon-message-plus-square"
    | "icon-message-question-circle"
    | "icon-message-question-square"
    | "icon-message-smile-circle"
    | "icon-message-smile-square"
    | "icon-message-square-01"
    | "icon-message-square-02"
    | "icon-message-text-circle-01"
    | "icon-message-text-circle-02"
    | "icon-message-text-square-01"
    | "icon-message-text-square-02"
    | "icon-message-x-circle"
    | "icon-message-x-square"
    | "icon-phone"
    | "icon-phone-call-01"
    | "icon-phone-call-02"
    | "icon-phone-hang-up"
    | "icon-phone-incoming-01"
    | "icon-phone-incoming-02"
    | "icon-phone-outgoing-01"
    | "icon-phone-outgoing-02"
    | "icon-phone-pause"
    | "icon-phone-plus"
    | "icon-phone-x"
    | "icon-send-01"
    | "icon-send-02"
    | "icon-send-03"
    | "icon-airplay"
    | "icon-airpods"
    | "icon-battery-charging-01"
    | "icon-battery-charging-02"
    | "icon-battery-empty"
    | "icon-battery-full"
    | "icon-battery-low"
    | "icon-battery-mid"
    | "icon-bluetooth-connect"
    | "icon-bluetooth-on"
    | "icon-bluetooth"
    | "icon-bluetooth-signal"
    | "icon-chrome-cast"
    | "icon-clapperboard"
    | "icon-disc-01"
    | "icon-disc-02"
    | "icon-fast-backward"
    | "icon-fast-forward"
    | "icon-film-01"
    | "icon-film-02"
    | "icon-film-03"
    | "icon-gaming-pad-01"
    | "icon-gaming-pad-02"
    | "icon-hard-drive"
    | "icon-headphones-01"
    | "icon-headphones-02"
    | "icon-keyboard-01"
    | "icon-keyboard-02"
    | "icon-laptop-01"
    | "icon-laptop-02"
    | "icon-lightbulb-01"
    | "icon-lightbulb-02"
    | "icon-lightbulb-03"
    | "icon-lightbulb-04"
    | "icon-lightbulb-05"
    | "icon-microphone-01"
    | "icon-microphone-02"
    | "icon-microphone-off-01"
    | "icon-microphone-off-02"
    | "icon-modem-01"
    | "icon-modem-02"
    | "icon-monitor-01"
    | "icon-monitor-02"
    | "icon-monitor-03"
    | "icon-monitor-04"
    | "icon-monitor-05"
    | "icon-mouse"
    | "icon-music-note-01"
    | "icon-music-note-02"
    | "icon-music"
    | "icon-pause-circle"
    | "icon-pause-square"
    | "icon-phone-01"
    | "icon-phone-02"
    | "icon-play"
    | "icon-play-circle"
    | "icon-play-square"
    | "icon-podcast"
    | "icon-power-01"
    | "icon-power-02"
    | "icon-power-03"
    | "icon-printer"
    | "icon-recording-01"
    | "icon-recording-02"
    | "icon-recording-03"
    | "icon-repeat-01"
    | "icon-repeat-02"
    | "icon-repeat-03"
    | "icon-repeat-04"
    | "icon-rss-01"
    | "icon-rss-02"
    | "icon-shuffle-01"
    | "icon-shuffle-02"
    | "icon-signal-01"
    | "icon-signal-02"
    | "icon-signal-03"
    | "icon-simcard"
    | "icon-skip-back"
    | "icon-skip-forward"
    | "icon-sliders-01"
    | "icon-sliders-02"
    | "icon-sliders-03"
    | "icon-sliders-04"
    | "icon-speaker-01"
    | "icon-speaker-02"
    | "icon-speaker-03"
    | "icon-stop"
    | "icon-stop-circle"
    | "icon-stop-square"
    | "icon-tablet-01"
    | "icon-tablet-02"
    | "icon-tv-01"
    | "icon-tv-02"
    | "icon-tv-03"
    | "icon-usb-flash-drive"
    | "icon-video-recorder"
    | "icon-video-recorder-off"
    | "icon-voicemail"
    | "icon-volume-max"
    | "icon-volume-min"
    | "icon-volume-minus"
    | "icon-volume-plus"
    | "icon-volume-x"
    | "icon-webcam-01"
    | "icon-webcam-02"
    | "icon-wifi"
    | "icon-wifi-off"
    | "icon-youtube"
    | "icon-face-id"
    | "icon-face-id-square"
    | "icon-file-lock-03"
    | "icon-file-lock-01"
    | "icon-file-lock-02"
    | "icon-file-shield-01"
    | "icon-file-shield-02"
    | "icon-file-shield-03"
    | "icon-file-shield-031"
    | "icon-fingerprint-01"
    | "icon-fingerprint-02"
    | "icon-fingerprint-03"
    | "icon-fingerprint-04"
    | "icon-folder-shield"
    | "icon-key-01"
    | "icon-key-02"
    | "icon-lock-01"
    | "icon-lock-02"
    | "icon-lock-03"
    | "icon-lock-04"
    | "icon-lock-keyhole-circle"
    | "icon-lock-keyhole-square"
    | "icon-lock-unlocked-01"
    | "icon-lock-unlocked-02"
    | "icon-lock-unlocked-03"
    | "icon-lock-unlocked-04"
    | "icon-passcode"
    | "icon-passcode-lock"
    | "icon-scan"
    | "icon-shield-03"
    | "icon-shield-dollar"
    | "icon-shield-off"
    | "icon-shield-plus"
    | "icon-shield-tick"
    | "icon-shield-zap"
    | "icon-shield-01"
    | "icon-shield-02"
    | "icon-align-center"
    | "icon-align-justify"
    | "icon-align-left"
    | "icon-align-right"
    | "icon-attachment-01"
    | "icon-attachment-02"
    | "icon-bezier-curve-01"
    | "icon-bezier-curve-02"
    | "icon-bezier-curve-03"
    | "icon-bold-01"
    | "icon-bold-02"
    | "icon-bold-square"
    | "icon-brush-01"
    | "icon-brush-02"
    | "icon-brush-03"
    | "icon-code-snippet-01"
    | "icon-code-snippet-02"
    | "icon-colors"
    | "icon-command"
    | "icon-contrast-01"
    | "icon-contrast-02"
    | "icon-contrast-03"
    | "icon-crop-01"
    | "icon-crop-02"
    | "icon-cursor-01"
    | "icon-cursor-02"
    | "icon-cursor-03"
    | "icon-cursor-04"
    | "icon-cursor-box"
    | "icon-cursor-click-01"
    | "icon-cursor-click-02"
    | "icon-delete"
    | "icon-dotpoints-01"
    | "icon-dotpoints-02"
    | "icon-drop"
    | "icon-dropper"
    | "icon-eraser"
    | "icon-feather"
    | "icon-figma"
    | "icon-framer"
    | "icon-hand"
    | "icon-heading-01"
    | "icon-heading-02"
    | "icon-heading-square"
    | "icon-image-indent-left"
    | "icon-image-indent-right"
    | "icon-italic-01"
    | "icon-italic-02"
    | "icon-italic-square"
    | "icon-left-indent-01"
    | "icon-left-indent-02"
    | "icon-letter-spacing-01"
    | "icon-letter-spacing-02"
    | "icon-line-height"
    | "icon-magic-wand-01"
    | "icon-magic-wand-02"
    | "icon-move"
    | "icon-paint"
    | "icon-paint-pour"
    | "icon-palette"
    | "icon-paragraph-spacing"
    | "icon-paragraph-wrap"
    | "icon-pen-tool-01"
    | "icon-pen-tool-02"
    | "icon-pen-tool-minus"
    | "icon-pen-tool-plus"
    | "icon-pencil-01"
    | "icon-pencil-02"
    | "icon-pencil-line"
    | "icon-perspective-01"
    | "icon-perspective-02"
    | "icon-pilcrow-01"
    | "icon-pilcrow-02"
    | "icon-pilcrow-square"
    | "icon-reflect-01"
    | "icon-reflect-02"
    | "icon-right-indent-01"
    | "icon-right-indent-02"
    | "icon-roller-brush"
    | "icon-scale-01"
    | "icon-scale-02"
    | "icon-scale-03"
    | "icon-scissors-01"
    | "icon-scissors-cut-01"
    | "icon-skew"
    | "icon-strikethrough-01"
    | "icon-strikethrough-02"
    | "icon-strikethrough-square"
    | "icon-subscript"
    | "icon-text-input"
    | "icon-transform"
    | "icon-type-01"
    | "icon-type-02"
    | "icon-type-square"
    | "icon-type-strikethrough-01"
    | "icon-type-strikethrough-02"
    | "icon-underline-01"
    | "icon-underline-02"
    | "icon-underline-square"
    | "icon-zoom-in"
    | "icon-zoom-out"
    | "icon-atom-01"
    | "icon-atom-02"
    | "icon-award-01"
    | "icon-award-02"
    | "icon-award-03"
    | "icon-award-04"
    | "icon-backpack"
    | "icon-beaker-01"
    | "icon-beaker-02"
    | "icon-book-closed"
    | "icon-book-open-01"
    | "icon-book-open-02"
    | "icon-briefcase-01"
    | "icon-briefcase-02"
    | "icon-calculator"
    | "icon-certificate-01"
    | "icon-certificate-02"
    | "icon-glasses-01"
    | "icon-globe-01"
    | "icon-globe-02"
    | "icon-graduation-hat-01"
    | "icon-graduation-hat-02"
    | "icon-ruler"
    | "icon-stand"
    | "icon-trophy-01"
    | "icon-trophy-02"
    | "icon-sale-02"
    | "icon-bank"
    | "icon-bank-note-01"
    | "icon-bank-note-02"
    | "icon-bank-note-03"
    | "icon-coins-01"
    | "icon-coins-02"
    | "icon-coins-03"
    | "icon-coins-04"
    | "icon-coins-hand"
    | "icon-coins-stacked-01"
    | "icon-coins-stacked-02"
    | "icon-coins-stacked-03"
    | "icon-coins-stacked-04"
    | "icon-coins-swap-01"
    | "icon-coins-swap-02"
    | "icon-credit-card-01"
    | "icon-credit-card-02"
    | "icon-credit-card-check"
    | "icon-credit-card-down"
    | "icon-credit-card-download"
    | "icon-credit-card-edit"
    | "icon-credit-card-minus"
    | "icon-credit-card-plus"
    | "icon-credit-card-lock"
    | "icon-credit-card-refresh"
    | "icon-credit-card-search"
    | "icon-credit-card-shield"
    | "icon-credit-card-up"
    | "icon-credit-card-upload"
    | "icon-credit-card-x"
    | "icon-cryptocurrency-01"
    | "icon-cryptocurrency-02"
    | "icon-cryptocurrency-03"
    | "icon-cryptocurrency-04"
    | "icon-currency-bitcoin"
    | "icon-currency-bitcoin-circle"
    | "icon-currency-dollar"
    | "icon-currency-dollar-circle"
    | "icon-ethereum-1"
    | "icon-currency-ethereum-circle"
    | "icon-currency-euro"
    | "icon-currency-euro-circle"
    | "icon-currency-pound"
    | "icon-currency-pound-circle"
    | "icon-currency-ruble"
    | "icon-currency-ruble-circle"
    | "icon-currency-rupee-circle"
    | "icon-currency-rupee"
    | "icon-currency-yen"
    | "icon-currency-yen-circle"
    | "icon-diamond-01"
    | "icon-diamond-02"
    | "icon-gift-01"
    | "icon-gift-02"
    | "icon-piggy-bank-01"
    | "icon-piggy-bank-02"
    | "icon-receipt"
    | "icon-receipt-check"
    | "icon-safe"
    | "icon-sale-01"
    | "icon-sale-021"
    | "icon-sale-03"
    | "icon-sale-04"
    | "icon-scales-01"
    | "icon-shopping-bag-01"
    | "icon-shopping-bag-02"
    | "icon-shopping-bag-03"
    | "icon-shopping-cart-01"
    | "icon-shopping-cart-02"
    | "icon-shopping-cart-03"
    | "icon-tag-01"
    | "icon-tag-02"
    | "icon-tag-03"
    | "icon-wallet-01"
    | "icon-wallet-02"
    | "icon-wallet-03"
    | "icon-wallet-04"
    | "icon-wallet-05"
    | "icon-bus"
    | "icon-compass-01"
    | "icon-compass-02"
    | "icon-compass-03"
    | "icon-flag-01"
    | "icon-flag-02"
    | "icon-flag-03"
    | "icon-flag-05"
    | "icon-flag-04"
    | "icon-flag-06"
    | "icon-globe-011"
    | "icon-globe-03"
    | "icon-globe-021"
    | "icon-globe-04"
    | "icon-globe-05"
    | "icon-globe-06"
    | "icon-luggage-01"
    | "icon-luggage-02"
    | "icon-luggage-03"
    | "icon-map-01"
    | "icon-map-02"
    | "icon-mark"
    | "icon-marker-pin-01"
    | "icon-marker-pin-02"
    | "icon-marker-pin-03"
    | "icon-marker-pin-04"
    | "icon-marker-pin-05"
    | "icon-marker-pin-06"
    | "icon-navigation-pointer-01"
    | "icon-navigation-pointer-02"
    | "icon-navigation-pointer-off-01"
    | "icon-navigation-pointer-off-02"
    | "icon-passport"
    | "icon-rocket-01"
    | "icon-rocket-02"
    | "icon-route"
    | "icon-ticket-01"
    | "icon-ticket-02"
    | "icon-train"
    | "icon-tram"
    | "icon-truck-02"
    | "icon-truck-01"
    | "icon-bar-chart-01"
    | "icon-bar-chart-02"
    | "icon-bar-chart-03"
    | "icon-bar-chart-04"
    | "icon-bar-chart-05"
    | "icon-bar-chart-06"
    | "icon-bar-chart-07"
    | "icon-bar-chart-08"
    | "icon-bar-chart-09"
    | "icon-bar-chart-10"
    | "icon-bar-chart-11"
    | "icon-bar-chart-12"
    | "icon-bar-chart-circle-01"
    | "icon-bar-chart-circle-02"
    | "icon-bar-chart-circle-03"
    | "icon-bar-chart-square-01"
    | "icon-bar-chart-square-02"
    | "icon-bar-chart-square-03"
    | "icon-bar-chart-square-down"
    | "icon-bar-chart-square-minus"
    | "icon-bar-chart-square-plus"
    | "icon-bar-chart-square-up"
    | "icon-bar-line-chart"
    | "icon-chart-breakout-circle"
    | "icon-chart-breakout-square"
    | "icon-horizontal-bar-chart-01"
    | "icon-horizontal-bar-chart-02"
    | "icon-horizontal-bar-chart-03"
    | "icon-line-chart-down-01"
    | "icon-line-chart-down-02"
    | "icon-line-chart-down-03"
    | "icon-line-chart-down-04"
    | "icon-line-chart-down-05"
    | "icon-line-chart-up-01"
    | "icon-line-chart-up-02"
    | "icon-line-chart-up-03"
    | "icon-line-chart-up-04"
    | "icon-line-chart-up-05"
    | "icon-pie-chart-01"
    | "icon-pie-chart-02"
    | "icon-pie-chart-03"
    | "icon-pie-chart-04"
    | "icon-presentation-chart-01"
    | "icon-presentation-chart-02"
    | "icon-presentation-chart-03"
    | "icon-trend-down-01"
    | "icon-trend-down-02"
    | "icon-trend-up-01"
    | "icon-trend-up-02"
    | "icon-arrow-block-down"
    | "icon-arrow-block-left"
    | "icon-arrow-block-right"
    | "icon-arrow-block-up"
    | "icon-arrow-circle-broken-down"
    | "icon-arrow-circle-broken-down-left"
    | "icon-arrow-circle-broken-down-right"
    | "icon-arrow-circle-broken-left"
    | "icon-arrow-circle-broken-right"
    | "icon-arrow-circle-broken-up"
    | "icon-arrow-circle-broken-up-left"
    | "icon-arrow-circle-broken-up-right"
    | "icon-arrow-circle-down"
    | "icon-arrow-circle-down-left"
    | "icon-arrow-circle-down-right"
    | "icon-arrow-circle-left"
    | "icon-arrow-circle-right"
    | "icon-arrow-circle-up"
    | "icon-arrow-circle-up-left"
    | "icon-arrow-circle-up-right"
    | "icon-arrow-down"
    | "icon-arrow-down-left"
    | "icon-arrow-down-right"
    | "icon-arrow-left"
    | "icon-arrow-narrow-down"
    | "icon-arrow-narrow-down-left"
    | "icon-arrow-narrow-down-right"
    | "icon-arrow-narrow-left"
    | "icon-arrow-narrow-right"
    | "icon-arrow-narrow-up"
    | "icon-arrow-narrow-up-left"
    | "icon-arrow-narrow-up-right"
    | "icon-arrow-right"
    | "icon-arrow-square-down"
    | "icon-arrow-square-down-left"
    | "icon-arrow-square-down-right"
    | "icon-arrow-square-left"
    | "icon-arrow-square-right"
    | "icon-arrow-square-up"
    | "icon-arrow-square-up-left"
    | "icon-arrow-square-up-right"
    | "icon-arrow-up"
    | "icon-arrow-up-left"
    | "icon-arrow-up-right"
    | "icon-arrows-down"
    | "icon-arrows-left"
    | "icon-arrows-right"
    | "icon-arrows-triangle"
    | "icon-arrows-up"
    | "icon-chevron-down"
    | "icon-chevron-down-double"
    | "icon-chevron-left"
    | "icon-chevron-left-double"
    | "icon-chevron-right"
    | "icon-chevron-right-double"
    | "icon-chevron-selector-horizontal"
    | "icon-chevron-selector-vertical"
    | "icon-chevron-up"
    | "icon-chevron-up-double"
    | "icon-corner-down-left"
    | "icon-corner-down-right"
    | "icon-corner-left-down"
    | "icon-corner-left-up"
    | "icon-corner-right-down"
    | "icon-corner-right-up"
    | "icon-corner-up-left"
    | "icon-corner-up-right"
    | "icon-expand-01"
    | "icon-expand-02"
    | "icon-expand-03"
    | "icon-expand-04"
    | "icon-expand-05"
    | "icon-expand-06"
    | "icon-flip-backward"
    | "icon-flip-forward"
    | "icon-infinity"
    | "icon-refresh-ccw-01"
    | "icon-refresh-ccw-02"
    | "icon-refresh-ccw-03"
    | "icon-refresh-ccw-04"
    | "icon-refresh-ccw-05"
    | "icon-refresh-cw-01"
    | "icon-refresh-cw-02"
    | "icon-refresh-cw-03"
    | "icon-refresh-cw-04"
    | "icon-refresh-cw-05"
    | "icon-reverse-left"
    | "icon-reverse-right"
    | "icon-switch-horizontal-01"
    | "icon-switch-horizontal-02"
    | "icon-switch-vertical-01"
    | "icon-switch-vertical-02"
    | "icon-file-01"
    | "icon-file-02"
    | "icon-file-03"
    | "icon-file-04"
    | "icon-file-05"
    | "icon-file-06"
    | "icon-file-07"
    | "icon-file-attachment-01"
    | "icon-file-attachment-02"
    | "icon-file-attachment-03"
    | "icon-file-attachment-04"
    | "icon-file-attachment-05"
    | "icon-file-check-01"
    | "icon-file-check-02"
    | "icon-file-download-01"
    | "icon-file-minus-01"
    | "icon-file-download-02"
    | "icon-file-heart-01"
    | "icon-file-download-03"
    | "icon-file-check-03"
    | "icon-file-heart-03"
    | "icon-file-x-03"
    | "icon-file-plus-03"
    | "icon-file-minus-03"
    | "icon-file-heart-02"
    | "icon-file-minus-02"
    | "icon-file-plus-01"
    | "icon-file-plus-02"
    | "icon-file-question-01"
    | "icon-file-question-02"
    | "icon-file-question-03"
    | "icon-file-x-01"
    | "icon-file-x-02"
    | "icon-file-search-01"
    | "icon-file-search-03"
    | "icon-file-search-02"
    | "icon-folder"
    | "icon-folder-lock"
    | "icon-folder-download"
    | "icon-folder-minus"
    | "icon-folder-plus"
    | "icon-folder-question"
    | "icon-folder-x"
    | "icon-folder-closed"
    | "icon-folder-search"
    | "icon-folder-check"
    | "icon-paperclip"
    | "icon-clipboard"
    | "icon-clipboard-attachment"
    | "icon-clipboard-download"
    | "icon-clipboard-check"
    | "icon-clipboard-x"
    | "icon-clipboard-plus"
    | "icon-clipboard-minus"
    | "icon-sticker-square"
    | "icon-sticker-circle"
    | "icon-box"
    | "icon-activity"
    | "icon-activity-heart"
    | "icon-anchor"
    | "icon-archive"
    | "icon-asterisk-01"
    | "icon-asterisk-02"
    | "icon-at-sign"
    | "icon-bookmark"
    | "icon-bookmark-add"
    | "icon-bookmark-check"
    | "icon-bookmark-minus"
    | "icon-bookmark-x"
    | "icon-building-01"
    | "icon-building-02"
    | "icon-building-03"
    | "icon-building-04"
    | "icon-building-05"
    | "icon-building-06"
    | "icon-building-07"
    | "icon-check"
    | "icon-check-circle"
    | "icon-check-circle-broken"
    | "icon-check-done-01"
    | "icon-check-done-02"
    | "icon-check-heart"
    | "icon-check-square"
    | "icon-check-square-broken"
    | "icon-check-verified-01"
    | "icon-check-verified-02"
    | "icon-cloud-blank-01"
    | "icon-cloud-blank-02"
    | "icon-copy-01"
    | "icon-copy-02"
    | "icon-copy-03"
    | "icon-copy-04"
    | "icon-copy-05"
    | "icon-copy-06"
    | "icon-copy-07"
    | "icon-divide-01"
    | "icon-divide-02"
    | "icon-divide-03"
    | "icon-dots-grid"
    | "icon-dots-horizontal"
    | "icon-dots-vertical"
    | "icon-download-01"
    | "icon-download-02"
    | "icon-download-03"
    | "icon-share-04"
    | "icon-share-05"
    | "icon-share-06"
    | "icon-slash-circle-01"
    | "icon-slash-circle-02"
    | "icon-slash-divider"
    | "icon-slash-octagon"
    | "icon-speedometer-01"
    | "icon-speedometer-02"
    | "icon-speedometer-03"
    | "icon-speedometer-04"
    | "icon-target-01"
    | "icon-target-02"
    | "icon-target-03"
    | "icon-target-04"
    | "icon-target-05"
    | "icon-toggle-01-left"
    | "icon-toggle-01-right"
    | "icon-toggle-02-left"
    | "icon-toggle-02-right"
    | "icon-toggle-03-left"
    | "icon-toggle-03-right"
    | "icon-tool-01"
    | "icon-tool-02"
    | "icon-translate-02"
    | "icon-trash-01"
    | "icon-trash-02"
    | "icon-trash-03"
    | "icon-trash-04"
    | "icon-upload-01"
    | "icon-upload-02"
    | "icon-upload-03"
    | "icon-upload-04"
    | "icon-upload-cloud-01"
    | "icon-upload-cloud-02"
    | "icon-x-circle"
    | "icon-x-close"
    | "icon-x-square"
    | "icon-google-chrome"
    | "icon-zap"
    | "icon-zap-circle"
    | "icon-zap-fast"
    | "icon-zap-off"
    | "icon-zap-square"
    | "icon-share-07"
    | "icon-link-broken-01"
    | "icon-link-broken-02"
    | "icon-link-external-01"
    | "icon-link-external-02"
    | "icon-loading-01"
    | "icon-loading-02"
    | "icon-loading-03"
    | "icon-log-in-01"
    | "icon-log-in-02"
    | "icon-log-in-03"
    | "icon-log-in-04"
    | "icon-log-out-01"
    | "icon-log-out-02"
    | "icon-log-out-03"
    | "icon-log-out-04"
    | "icon-medical-circle"
    | "icon-medical-cross"
    | "icon-medical-square"
    | "icon-menu-01"
    | "icon-menu-02"
    | "icon-menu-03"
    | "icon-menu-04"
    | "icon-menu-05"
    | "icon-minus"
    | "icon-minus-circle"
    | "icon-minus-square"
    | "icon-percent-01"
    | "icon-percent-02"
    | "icon-percent-03"
    | "icon-pin-01"
    | "icon-pin-02"
    | "icon-placeholder"
    | "icon-plus"
    | "icon-plus-circle"
    | "icon-plus-square"
    | "icon-save-01"
    | "icon-save-02"
    | "icon-save-03"
    | "icon-search-lg"
    | "icon-search-md"
    | "icon-search-refraction"
    | "icon-search-sm"
    | "icon-settings-01"
    | "icon-settings-02"
    | "icon-settings-03"
    | "icon-settings-04"
    | "icon-share-01"
    | "icon-share-02"
    | "icon-share-03"
    | "icon-download-04"
    | "icon-download-cloud-01"
    | "icon-download-cloud-02"
    | "icon-edit-01"
    | "icon-edit-02"
    | "icon-edit-03"
    | "icon-edit-04"
    | "icon-edit-05"
    | "icon-equal"
    | "icon-equal-not"
    | "icon-eye"
    | "icon-eye-off"
    | "icon-filter-funnel-01"
    | "icon-filter-funnel-02"
    | "icon-filter-lines"
    | "icon-hash-01"
    | "icon-hash-02"
    | "icon-heart"
    | "icon-heart-hand"
    | "icon-heart-circle"
    | "icon-heart-octagon"
    | "icon-help-hexagon"
    | "icon-heart-rounded"
    | "icon-heart-square"
    | "icon-hearts"
    | "icon-help-circle"
    | "icon-help-octagon-1"
    | "icon-help-octagon"
    | "icon-help-square"
    | "icon-home-01"
    | "icon-home-02"
    | "icon-home-03"
    | "icon-home-04"
    | "icon-home-05"
    | "icon-building-08"
    | "icon-home-line"
    | "icon-home-smile"
    | "icon-info-circle"
    | "icon-info-hexagon"
    | "icon-info-octagon"
    | "icon-info-square"
    | "icon-life-buoy-01"
    | "icon-life-buoy-02"
    | "icon-link-01"
    | "icon-link-02"
    | "icon-link-03"
    | "icon-link-04"
    | "icon-link-05"
    | "icon-opensea"
    | "icon-discord";
}
