import Icon from "../shared/Icon";
import {
  DexToolsIcon,
  EtherscanIcon,
  TelegramIcon,
  UniswapIcon,
} from "../shared/Svgs";

const getCurrentYear = () => new Date().getFullYear();

const TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
const TOKEN_PAIR_CONTRACT_ADDRESS =
  process.env.REACT_APP_TOKEN_PAIR_CONTRACT_ADDRESS;
const uniswapLink = `https://app.uniswap.org/#/swap?exactField=input&exactAmount=0.05&outputCurrency=${TOKEN_CONTRACT_ADDRESS}&inputCurrency=ETH`;
const dexToolsLink = `https://www.dextools.io/app/en/ether/pair-explorer/${TOKEN_PAIR_CONTRACT_ADDRESS}`;

export default function Footer() {
  return (
    <footer className="flex flex-col footer justify-center items-center bg-black text-white text-neutral-content w-full py-14">
      <p className="lg:max-w-4xl text-center mb-20 w-[80%] ">
        $WHALEZ is a meme coin with no intrinsic value and a financial return
        should not be expected. Nothing on this website should be taken as
        financial advice and any involvement in our project from your part
        should come with careful consideration.
      </p>
      <a href="https://whaletogether.com/" target="_blank" rel="noreferrer">
        <img
          src="/images/whalez-coin.png"
          className="h-14 mb-3 rounded-full"
          alt="WhaleTogether Logo"
        />
      </a>

      <div className="mb-3 flex gap-3">
        <a
          href="https://twitter.com/WhaleTogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-whale-twitter"
            className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
        <a
          href="https://discord.gg/whaletogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-discord"
            className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
        <a
          href="https://opensea.io/collection/whaletogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-opensea"
            className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
        <a
          href="https://t.me/whalezcoin"
          target="_blank"
          rel="noreferrer"
          className="ml-1 mt-0.5 text-white"
        >
          <TelegramIcon className="relative fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />
        </a>
        <a
          href={`https://etherscan.io/address/${TOKEN_CONTRACT_ADDRESS}`}
          target="_blank"
          rel="noreferrer"
          className="ml-1 mt-1"
        >
          <EtherscanIcon className="text-3xl relative hover:scale-110 transform transition duration-300 ease-in-out" />
        </a>

        <a
          href={uniswapLink}
          target="_blank"
          rel="noreferrer"
          className="mt-1 text-white"
        >
          <UniswapIcon className="relative -top-1 fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />
        </a>
        <a
          href={dexToolsLink}
          target="_blank"
          rel="noreferrer"
          className="ml-1 mt-1 text-white"
        >
          <DexToolsIcon className="fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />
        </a>
      </div>

      <p className="text-xs">
        Copyright © {getCurrentYear()} - All right reserved
      </p>
      <p className="text-xs mt-2">
        Built with 💜 by{" "}
        <a
          href="https://moonlabs.gg/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          MoonLabs
        </a>
        <span className="relative -top-0.5">3</span>
      </p>
    </footer>
  );
}
