import { twMerge } from "tailwind-merge";

export default function ErrorPage({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  return (
    <>
      <div
        className={twMerge(
          "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-gradient-rare z-50 text-black",
        )}
      >
        <div className="flex flex-col justify-center items-center w-full p-3 z-20 text-center">
          <h1 className="sm:block text-6xl sm:text-8xl md:text-[7rem] font-goodgirl mb-5">
            404 Not Found
          </h1>
          <h1 className="text-xl sm:text-3xl font-semibold">
            Oops, looks like we hit a snag - that page is missing!
          </h1>
        </div>
      </div>
    </>
  );
}
