import { Link } from "react-router-dom";
import { ResponsivePie } from "@nivo/pie";
import { useMediaQuery } from "react-responsive";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import Layout from "../components/Layout";

import Button from "../components/shared/Button";
import { useEffect, useState } from "react";
import Icon from "../components/shared/Icon";
import {
  DexToolsIcon,
  EtherscanIcon,
  TelegramIcon,
  UniswapIcon,
} from "../components/shared/Svgs";

const TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
const TOKEN_PAIR_CONTRACT_ADDRESS =
  process.env.REACT_APP_TOKEN_PAIR_CONTRACT_ADDRESS;
const uniswapLink = `https://app.uniswap.org/#/swap?exactField=input&exactAmount=0.05&outputCurrency=${TOKEN_CONTRACT_ADDRESS}&inputCurrency=ETH`;
const dexToolsLink = `https://www.dextools.io/app/en/ether/pair-explorer/${TOKEN_PAIR_CONTRACT_ADDRESS}`;

const data = [
  {
    id: "LP",
    label: "LP",
    value: 77,
    color: "hsl(71, 70%, 50%)",
  },
  {
    id: "NFT Holders",
    label: "NFT Holders",
    value: 5,
    color: "hsl(177, 70%, 50%)",
  },
  {
    id: "Reserve for NEO Alphie",
    label: "Reserve for NEO Alphie",
    value: 2,
    color: "hsl(95, 70%, 50%)",
  },
  {
    id: "Community's War Chest",
    label: "Community's War Chest",
    value: 5,
    color: "hsl(95, 70%, 50%)",
  },
  {
    id: "Marketing",
    label: "Marketing",
    value: 2,
    color: "hsl(95, 70%, 50%)",
  },
  {
    id: "Team",
    label: "Team",
    value: 5,
    color: "hsl(95, 70%, 50%)",
  },
  {
    id: "CEX Listing",
    label: "CEX Listing",
    value: 4,
    color: "hsl(263, 70%, 50%)",
  },
];

const FAQItem = ({
  id,
  question,
  answer,
}: {
  id: string;
  question: string;
  answer: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(id === "mint" ? true : false);

  return (
    <>
      <a className="relative -top-[200px]" id={id}></a>
      <div className="border-b border-gray-300 pb-10" key={id}>
        <div
          className="flex justify-between items-center cursor-pointer mt-10"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="text-3xl sm:text-5xl font-medium">{question}</h3>
          {isOpen ? (
            <Icon
              name="icon-chevron-up"
              className="text-3xl relative top-0.5 ml-1"
            />
          ) : (
            <Icon
              name="icon-chevron-down"
              className="text-3xl relative top-0.5 ml-1"
            />
          )}
        </div>
        {isOpen && (
          <p className="mt-8 text-xl sm:text-2xl text-white">{answer}</p>
        )}
      </div>
    </>
  );
};

const TeamItem = ({
  id,
  name,
  position,
  positionExtra,
  image,
  link,
}: {
  id: number;
  name: string;
  position: string;
  positionExtra: string;
  image: string;
  link: {
    show: boolean;
    data: {
      linkedin: string;
      twitter: string;
    };
  };
}) => {
  return (
    <>
      <a
        href={link.data.twitter}
        target="_blank"
        rel="noreferrer"
        className="w-[100%] sm:w-[40%] xl:w-[28%]"
      >
        <div className="" key={id}>
          <div className="flex justify-start items-center cursor-pointer mt-10 gap-5">
            <img alt={name} src={image} className="w-32 rounded-lg" />
            <div>
              <h3 className="text-xl sm:text-2xl font-medium">{name}</h3>
              <p className="text-md sm:text-md text-white">{position}</p>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

const faqs = [
  {
    id: "whaletogether",
    question: "1. Who’s WhaleTogether?",
    answer: (
      <p>
        <a
          href="https://whaletogether.com/"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          WhaleTogether
        </a>{" "}
        is a utility-focused NFT project that launched back in February, 2022.
        We’ve created awesome tools for our holders including a rarity and
        sniper browser extension for OpenSea, a private node, a gas-less
        whitelist marketplace for our $MOON utility token and a web-based tool
        with fast live listings and an amazing auto-snipe feature.{" "}
        <a
          href="https://opensea.io/collection/whaletogether"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Read our OS description here
        </a>
      </p>
    ),
  },
  {
    id: "zksync",
    question: "2. What is zkSync Era?",
    answer: (
      <p>
        <a
          href="https://zksync.io/"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          zkSync
        </a>{" "}
        is a Layer-2 scaling solution for Ethereum that enables fast and
        low-cost transactions with cutting-edge ZK tech, and enhances the user
        experience for dApps and NFTs with hyperscalability.,
      </p>
    ),
  },
  {
    id: "zkTogether",
    question: "3. What is zkTogether?",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          {`It’s a community-driven Open Mint NFT project by WhaleTogether that
          aims to win together!`}
        </p>
        <p>
          We do this by leveraging the highly-anticipated upcoming zkSync
          airdrop people are starting to grind for.
        </p>
        <p>
          By minting our NFT, we hope to give you a better chance at the
          upcoming zkSync airdrop as previous airdrops (Arbitrum, etc) have
          required tasks such as minting, bridging, swapping, etc as a
          requirement.
        </p>
      </div>
    ),
  },
  {
    id: "benefits",
    question: "4. What are the benefits of participating in the mint?",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          Apart from leaving a{" "}
          <u>
            <b>footprint</b>
          </u>{" "}
          on zkSync when you bridge ETH and make a transaction (mint), which
          increases your chances of getting a larger portion of the zkSync token
          airdrop…
        </p>
        <p>
          You will also have the chance to earn a commission by referring others
          to mint, as well as potentially qualifying for an allocation (based on
          milestones) of our upcoming $WHALEZ coin.
        </p>
      </div>
    ),
  },
  {
    id: "referral",
    question: "5. Referral commission:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          Once you mint our zkTogether NFT for a low fee of 0.005 ETH (public)
          or FREE / 0.004 ETH (
          <a
            href="https://opensea.io/collection/whaletogether"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            WhaleTogether
          </a>{" "}
          and{" "}
          <a
            href="https://opensea.io/collection/blackdust"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Black Dust
          </a>{" "}
          holders), you can refer others to mint via your unique referral link
          and earn a commission as follows:
        </p>
        <div className="overflow-x-auto mt-6">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Benefit</th>
                <th className="px-4 py-2">WT/BD</th>
                <th className="px-4 py-2">Public</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">FREE Mint</td>
                <td className="border px-4 py-2">1 per NFT held</td>
                <td className="border px-4 py-2">No</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Mint Price</td>
                <td className="border px-4 py-2">0.004 ETH</td>
                <td className="border px-4 py-2">0.005 ETH</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Commission</td>
                <td className="border px-4 py-2">35%</td>
                <td className="border px-4 py-2">25%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    id: "whalez",
    question: "6. $WHALEZ coin:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          {`It's meme coin season, isn't it? Well, we bring you something with a
          bit of a twist and a purpose.`}
        </p>
        <p>
          {`We're launching a coin VERY soon and the idea is to first launch on
          the Ethereum Mainnet (greater visibility and volume) and then bridge
          to other chains, starting with zkSync for people to accumulate those
          transactions for that upcoming airdrop.`}
        </p>
        <p>
          {`We've also taken inspiration from MongsNFT/$MONGS and will reward
          zkTogether Open Edition NFT, WhaleTogether and Black Dust NFT holders with an allocation of token based on milestones
          achieved as the coin reaches stability against "dumps".`}
        </p>
        <p>More info to come VERY soon!</p>
      </div>
    ),
  },
  {
    id: "mint",
    question: "7. How to bridge to and mint NFT on zkSync:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          1. Add zkSync Era to your Metamask wallet. The quickest way to go to{" "}
          <a
            href="https://chainlist.org/?search=zksync"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Chainlist.org
          </a>{" "}
          and click “connect wallet” on the zkSync Era Mainnet.
        </p>
        <p>
          2. Use the official bridge at{" "}
          <a
            href="https://bridge.zksync.io/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://bridge.zksync.io/
          </a>{" "}
          to bridge ETH to zkSync (cost $10-20 depending on gas fees)
        </p>
        <p>
          3. Mint your NFTs by heading over to{" "}
          <Link to="/mint" className="underline">
            Mint page
          </Link>
        </p>
        <p>
          Alternatively, you can read this{" "}
          <a
            href="https://docs.google.com/document/d/1Qvec4SgWs8Dwq1Rf1eHqvdHmbdlfIny1FLKwVt0Zj-E/edit"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            document
          </a>{" "}
          for cheaper bridging options and how to add zkSync manually to
          Metamask if you don't want to use Chainlist's "one click" option.
        </p>
      </div>
    ),
  },
];

const team = [
  {
    id: 1,
    name: "Greg",
    position: "Co-founder",
    positionExtra: "",
    image: "/images/crew/moonlabs/01.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/gregpalma",
        twitter: "https://twitter.com/GregLuis100",
      },
    },
  },
  {
    id: 2,
    name: "Natapol",
    position: "Co-founder",
    positionExtra: "/ BD to the Bone",
    image: "/images/crew/moonlabs/02.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/natapol/",
        twitter: "https://twitter.com/Supmanu",
      },
    },
  },
  {
    id: 3,
    name: "Max",
    position: "Co-founder",
    positionExtra: "/ Metalhead",
    image: "/images/crew/moonlabs/03.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/maxlimsukhawat/",
        twitter: "https://twitter.com/maxmillions_eth",
      },
    },
  },
  {
    id: 4,
    name: "Paan Nitta",
    position: "Designer Queen",
    positionExtra: "",
    image: "/images/crew/moonlabs/04.jpg",
    link: {
      show: true,
      data: {
        linkedin: "#",
        twitter: "https://twitter.com/PaanNitta",
      },
    },
  },
  {
    id: 5,
    name: "Plug",
    position: "Design Evangelist",
    positionExtra: "",
    image: "/images/crew/moonlabs/05.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/thammanoon-so/",
        twitter: "https://twitter.com/plugin87",
      },
    },
  },
  {
    id: 7,
    name: "Olaf",
    position: "Caffeinated Engineer",
    positionExtra: "",
    image: "/images/crew/moonlabs/07.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/peerachat",
        twitter: "https://twitter.com/soulbalz",
      },
    },
  },
  {
    id: 8,
    name: "Pao",
    position: "Coding Owl",
    positionExtra: "",
    image: "/images/crew/moonlabs/08.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/wutthichai-boonraksa/",
        twitter: "https://twitter.com/blackdotd",
      },
    },
  },
  {
    id: 15,
    name: "Jakkrit",
    position: "Coding Owl",
    positionExtra: "",
    image: "/images/crew/moonlabs/15.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/jakkrit-aryamuang/",
        twitter: "https://twitter.com/_jakkr",
      },
    },
  },
  {
    id: 9,
    name: "Wil Hackworth",
    position: "Community",
    positionExtra: "",
    image: "/images/crew/moonlabs/09.jpg",
    link: {
      show: true,
      data: {
        linkedin: "https://www.linkedin.com/in/william-hackworth-b04b3794/",
        twitter: "https://twitter.com/bigwil2k3",
      },
    },
  },
  {
    id: 10,
    name: "JymmyL",
    position: "Community",
    positionExtra: "",
    image: "/images/crew/moonlabs/10.jpg",
    link: {
      show: true,
      data: {
        linkedin: "#",
        twitter: "https://twitter.com/jimtjl",
      },
    },
  },
  {
    id: 11,
    name: "o1te",
    position: "Community",
    positionExtra: "",
    image: "/images/crew/moonlabs/11.jpg",
    link: {
      show: true,
      data: {
        linkedin: "#",
        twitter: "https://twitter.com/B060A22",
      },
    },
  },
];
function RootPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [windowWidthSize, setWindowWidthSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidthSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Layout>
      <div className="container mx-auto px-4 text-white">
        <div className="flex flex-wrap-reverse lg:flex-nowrap items-center justify-center">
          <div className="w-full lg:w-1/2 p-4 text-center lg:text-left relative">
            <h1 className="font-bold sm:block text-5xl sm:text-[5.5rem] lg:text-[4.5rem]  xl:text-[6rem] 2xl:text-[7rem] pt-0 pb-7 sm:py-8 xl:py-10 bg-clip-text text-transparent bg-gradient-primary tracking-wide">
              The Premium Memecoin
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl xl:text-3xl w-[100%] lg:max-w-screen-lg ">
              $WHALEZ is creating a new category for memecoins…
            </p>
            <div className="flex gap-3 w-full justify-center lg:justify-start">
              <a href={uniswapLink} target="_blank" rel="noreferrer">
                <Button className="bg-gradient-primary font-bold text-white py-4 px-10 text-2xl mt-7 sm:mt-12 lg:mt-10 hover:scale-105 transform transition duration-300 ease-in-out">
                  Buy $WHALEZ
                </Button>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-0 sm:p-4">
            <img
              src="/images/alphies/whalez-pirates.png"
              alt="Whalez Pirates"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full items-center text-white bg-gradient-primary-a-black">
        <div className="flex flex-col justify-start items-start p-5 sm:p-20 mb-36 md:my-44 pt-20 md:pt-0">
          <div id="vision" className="relative top-[25px]" />
          <div className="relative mt-10 sm:mt-24 mx-auto mb-10">
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[30%] sm:w-[25%] absolute -top-3 sm:top-10 right-20 sm:right-32 xl:right-72 transform translate-x-1/2 md:right-44"
            />

            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[30%] relative top-3 sm:top-10 left-3 sm:left-10 md:left-20"
            />
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[25%] sm:w-[20%] relative top-10 sm:top-16 left-10 sm:left-32 md:left-44"
            />

            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[80%] sm:w-[60%] absolute -top-5 sm:top-0 left-1/2 transform -translate-x-1/2"
            />

            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[50%] sm:w-[40%] absolute top-20 sm:top-32 md:top-36 xl:top-44 right-0 sm:right-10"
            />
          </div>

          <h1 className="sm:block text-7xl sm:text-[7.5rem] py-8 xl:py-10 tracking-wide text-center w-full font-bold sm:mt-20 md:mt-10 xl:mt-0">
            <span className="">Vision</span>
          </h1>

          <p className="text-center text-2xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl max-w-screen-xl leading-[2rem] sm:leading-[2.7rem] md:leading-[3.5rem] lg:leading-[3.5rem] xl:leading-[3.5rem]">
            We call it Memium Coin - A premium meme coin with a clear purpose,
            unique benefits and a doxxed professional team. We believe in
            elevating the memecoin experience.
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full items-center text-white bg-black">
        <div className="flex flex-col justify-start items-center p-5 sm:p-20 md:my-36 pt-20 md:pt-0">
          <img
            src="/images/whalez-coin.png"
            alt="Whalez Coin"
            className="rounded-full relative h-52 shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
          />
          <h1 className="font-athelas sm:block text-7xl sm:text-[7.5rem] pt-5 tracking-wide text-center w-full font-bold">
            $WHALEZ
          </h1>
          <p className="mt-5 text-center text-2xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-4xl max-w-screen-xl leading-[2rem] sm:leading-[2.7rem] md:leading-[3.5rem] lg:leading-[3.5rem] xl:leading-[3.5rem]">
            Our goal is to increase your chances of securing upcoming hyped
            airdrops by initially launching on ETH mainnet and later exploring
            bridging to zkSync and other chains/layers.{" "}
            <a
              href="https://zk.whaletogether.com/"
              target="_blank"
              rel="noreferrer"
              className="underline cursor-pointer"
            >
              Read more here
            </a>
          </p>
          <div className="flex flex-col sm:flex-row gap-3 w-full justify-center lg:justify-center px-10 mt-5">
            <a href={uniswapLink} target="_blank" rel="noreferrer">
              <Button className="w-full flex gap-3 items-center justify-center bg-gradient-primary font-bold text-white py-3 px-10 text-2xl mt-3 hover:scale-105 transform transition duration-300 ease-in-out">
                <UniswapIcon className="relative -top-1 fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />{" "}
                <span>Uniswap</span>
              </Button>
            </a>

            <a href={dexToolsLink} target="_blank" rel="noreferrer">
              <Button className="w-full flex gap-3 items-center justify-center bg-gradient-primary font-bold text-white py-3 px-10 text-2xl sm:mt-3 hover:scale-105 transform transition duration-300 ease-in-out">
                <DexToolsIcon className="fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />{" "}
                DEXTools
              </Button>
            </a>
            <a
              href={`https://etherscan.io/address/${TOKEN_CONTRACT_ADDRESS}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-full flex gap-3 items-center justify-center bg-gradient-primary font-bold text-white py-3 px-10 text-2xl sm:mt-3 hover:scale-105 transform transition duration-300 ease-in-out">
                <EtherscanIcon className="fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />{" "}
                Etherscan
              </Button>
            </a>
          </div>

          {/* <iframe
            src={uniswapLink}
            height="660px"
            width="100%"
            className="mt-10 rounded-lg"
            //           style="
            //   border: 0;
            //   margin: 0 auto;
            //   margin-bottom: .5rem;
            //   display: block;
            //   border-radius: 10px;
            //   max-width: 960px;
            //   min-width: 300px;
            // "
          /> */}
        </div>
      </div>

      <div className="flex flex-col w-full py-10 pb-[15rem] items-center text-white bg-gradient-primary-alt-reverse overflow-hidden">
        <div className="flex flex-col justify-start items-center p-10 sm:p-20 md:mt-16">
          <h1 className="text-center sm:block text-6xl sm:text-[7.5rem] md:text-[7rem] py-8 xl:py-10 font-bold">
            Brought To You By
          </h1>

          <p className="text-center text-2xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl max-w-screen-xl leading-[2rem] sm:leading-[2.7rem] md:leading-[3.5rem] lg:leading-[3.5rem] xl:leading-[3.5rem]">
            <a
              href="https://opensea.io/collection/whaletogether"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              WhaleTogether
            </a>
            , a doxxed team of 11 professionals who have been creating useful
            tools for holders since Feb, 2022. Holders will claim $WHALEZ
            airdrop based on milestones.
          </p>
        </div>
        <div className="flex justify-center gap-3 lg:gap-7 w-screen">
          <img
            src="/images/alphies/alphies-random.gif"
            alt="zkTogether-nft"
            className="md:hidden w-[90%] md:w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-607.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[90%] md:w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-639.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-647.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-662.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-733.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-751.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-758.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
        </div>

        <div className="hidden md:flex justify-center gap-3 lg:gap-7 w-screen mt-5 md:ml-24">
          <img
            src="/images/alphies/AlphieWhales-10530.png"
            alt="zkTogether-nft"
            className="w-[90%] md:w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10536.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10541.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10547.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10573.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10592.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
          <img
            src="/images/alphies/AlphieWhales-10593.png"
            alt="zkTogether-nft"
            className="hidden md:block w-[15%] rounded-lg"
          />
        </div>
        <a
          href="https://opensea.io/collection/whaletogether"
          target="_blank"
          rel="noreferrer"
          className="mt-3"
        >
          <Button className="bg-white font-bold text-black shadow-lg py-4 px-10 text-2xl mt-7 sm:mt-12 lg:mt-10 hover:scale-105 transform transition duration-300 ease-in-out">
            Buy WhaleTogether NFT
          </Button>
        </a>
      </div>

      <div className="flex flex-col w-full py-20 md:py-44 items-center text-white bg-primary">
        <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-4 mb-3">
          {/* <img
            src="/images/whalez-coin.png"
            alt="Whalez Coin"
            className="rounded-full relative h-20 sm:h-28 shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
          /> */}
          <h2 className="text-5xl sm:text-9xl font-bold">TOKENOMICS</h2>
        </div>
        <h4 className="text-xl sm:text-5xl font-bold mb-5 sm:mb-20">
          777,000,000,000 $WHALEZ
        </h4>
        <div className="mx-auto xl:max-w-6xl 2xl:max-w-9xl w-full flex flex-col justify-center items-start gap-5">
          <div
            className={`w-[100%] mx-auto text-white ${
              isDesktopOrLaptop
                ? "h-[500px]"
                : isMobile
                ? "h-[530px]"
                : "h-[650px]"
            }`}
          >
            <ResponsivePie
              theme={{
                textColor: "#fff",
                fontSize: 20,
                tooltip: {
                  container: {
                    background: "#ffffff",
                    color: "#333333",
                    fontSize: 24,
                  },
                },
              }}
              data={data}
              sortByValue={true}
              margin={{
                top: isMobile ? -50 : 40,
                right: 80,
                bottom: isDesktopOrLaptop ? 0 : isMobile ? 200 : 250,
                left: 80,
              }}
              innerRadius={0}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={18}
              borderWidth={0}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              colors={[
                "#7222F0",
                "#F72DB1",
                "#291166",
                "#5D2A8C",
                "#FF8B00",
                "#01AA51",
                "#12CED6",
                "#96C93D",
              ]}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#fff"
              arcLabelsRadiusOffset={0.65}
              arcLinkLabelsThickness={3}
              arcLinkLabelsDiagonalLength={20}
              arcLinkLabelsColor="#ffffff"
              arcLinkLabel={(e) => e.id + " (" + e.value + "%)"}
              arcLabel={(e) => e.value + "%"}
              arcLabelsSkipAngle={0}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              enableArcLinkLabels={isMobile ? false : true}
              legends={[
                {
                  anchor: "right",
                  direction: "column",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#fff",
                      },
                    },
                  ],
                  itemHeight: 25,
                  itemTextColor: "#fff",
                  itemWidth: 200,
                  symbolShape: "circle",
                  symbolSize: 10,
                  translateY: isDesktopOrLaptop ? -20 : isMobile ? 250 : 330,
                  translateX: isDesktopOrLaptop
                    ? -20
                    : -windowWidthSize / 2 + 180,
                },
              ]}
              tooltip={({ datum: { id, value, color } }) => (
                <div
                  style={{
                    padding: 12,
                    color: "#fff",
                    background: "#222222",
                  }}
                >
                  <strong>
                    {id}: {value}%
                  </strong>
                </div>
              )}
              enableArcLabels={false}
            />
          </div>
          <div className="max-w-2xl 2xl:max-w-4xl mx-auto px-10 mt-10">
            <div className="mt-8 text-xl sm:text-2xl 2xl:text-4xl text-white flex flex-col gap-10">
              <p>
                - Team - 5% Immediately invested into LP with a vested period of
                6 and 12 months.
              </p>
              <p>
                - NFT Holders - 5% allocations of the supply based on milestones
                for the holders of{" "}
                <a
                  href="https://opensea.io/collection/whaletogether"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  WhaleTogether
                </a>
                ,{" "}
                <a
                  href="https://opensea.io/collection/blackdust"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Black Dust
                </a>{" "}
                and{" "}
                <a
                  href="https://zk.whaletogether.com/"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  zkTogether
                </a>{" "}
              </p>
              <p>- Neo Alphie NFTs - 2% 👀</p>
            </div>

            <div className="my-10 flex flex-col gap-5 -ml-6">
              <table className="table-auto mx-auto sm:text-2xl">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Milestones</th>
                    <th className="px-4 py-2"></th>
                    <th className="px-4 py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-4 py-2">Launch</td>
                    <td className="border px-4 py-2">1%</td>
                    <td className="border px-4 py-2">7,770,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">100K</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">1M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">5M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">10M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">100M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">250M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">500M</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">1B</td>
                    <td className="border px-4 py-2">0.5%</td>
                    <td className="border px-4 py-2">3,885,000,000</td>
                  </tr>
                  <tr className="font-bold bg-white text-black">
                    <td className="border px-4 py-2">Total</td>
                    <td className="border px-4 py-2">5%</td>
                    <td className="border px-4 py-2">38,850,000,000</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-lg mx-auto">* Inspired by MongsNFT/$MONG</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-20 items-center text-white bg-primary">
        <div className="flex mb-8 gap-4">
          <h2 className="text-5xl sm:text-9xl font-bold">How to Buy</h2>
        </div>
        <div className="mx-auto xl:max-w-6xl w-full flex flex-col justify-center items-start gap-14 text-white p-10">
          <div className="flex flex-col gap-5">
            <h3 className="text-2xl sm:text-4xl font-bold">
              Step 1: Create a MetaMask Wallet
            </h3>
            <p className="text-lg sm:text-xl">
              $WHALEZ token is available on the Ethereum blockchain. MetaMask is
              a third party ERC20 (Ethereum) browser wallet, and the very best
              at that! On Google Chrome, visit{" "}
              <a
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer"
              >
                metamask.io
              </a>{" "}
              to download the extension and set up a wallet. On mobile? Get
              MetaMask's app for iPhone or Android.
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <h3 className="text-2xl sm:text-4xl font-bold">
              Step 2: Send ETH to MetaMask
            </h3>
            <p className="text-lg sm:text-xl">
              Acquire Ethereum through MetaMask itself or transfer it to your
              MetaMask wallet address from another wallet (e.g.{" "}
              <a
                href="https://www.coinbase.com/"
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer"
              >
                Coinbase
              </a>{" "}
              or{" "}
              <a
                href="https://binance.com/"
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer"
              >
                Binance
              </a>
              ).
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <h3 className="text-2xl sm:text-4xl font-bold">
              Step 3: Swap ETH for $WHALEZ on Uniswap
            </h3>
            <p className="text-lg sm:text-xl">
              Go to{" "}
              <a
                href={uniswapLink}
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer"
              >
                Uniswap
              </a>{" "}
              Connect your wallet. Paste the $WHALEZ token address -{" "}
              <a
                href={`https://etherscan.io/address/${TOKEN_CONTRACT_ADDRESS}`}
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer"
              >
                {TOKEN_CONTRACT_ADDRESS}
              </a>{" "}
              into Uniswap, select WHALEZ, and confirm. Enter the amount of $ETH
              you would like to swap for $WHALEZ. Click swap and confirm the
              transaction in MetaMask.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-20 items-center text-white bg-primary">
        <div className="flex mb-8 gap-4">
          <h2 className="text-5xl sm:text-9xl font-bold">MILESTONES</h2>
        </div>
        <div className="mx-auto xl:max-w-6xl w-full flex flex-row justify-center items-start gap-5 text-black p-10">
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#F72DB1", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  #F72DB1",
              }}
              iconStyle={{ background: "#F72DB1", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title text-2xl font-bold">
                Phase 1: Launch
              </h3>
              <div className="mt-3">
                <ul>
                  <li></li>
                  <li>Token Launch</li>
                  <li>Website Launch</li>
                  <li>Telegram Launch</li>
                  <li>500+ Holders</li>
                </ul>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: "#fff", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title text-2xl font-bold">
                Phase 2: Growth
              </h3>
              <div className="mt-3">
                <ul>
                  <li>Exclusive collaborations</li>
                  <li>Explore other chains/layers</li>
                  <li>10,000 Holders</li>
                  <li>CEX Listings</li>
                </ul>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: "#fff", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title text-2xl font-bold">
                Phase 3: the Moon
              </h3>
              <div className="mt-3">
                <ul>
                  <li>Neo Alphie NFTs</li>
                  <li>100,000 Holders</li>
                  <li>[Redacted]</li>
                </ul>
              </div>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
      <div className="flex flex-col w-full py-20 items-center text-white bg-black">
        <div className="w-[80%] sm:w-[90%] xl:max-w-5xl mx-auto xl:w-full">
          <h2 className="text-7xl sm:text-9xl font-bold mb-8">TEAM</h2>
          <div className="flex flex-wrap gap-y-0 gap-x-20">
            {team.map(({ id, name, position, positionExtra, image, link }) => (
              <TeamItem
                id={id}
                name={name}
                position={position}
                positionExtra={positionExtra}
                image={image}
                link={link}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col 2xl:flex-row w-full py-44 justify-center items-center bg-gradient-primary-alt-2 text-white mx-auto bg-cover bg-center">
        <img
          src="/images/alphies/whalez-treasure.png"
          alt="Whalez treasure"
          className="lg:block w-[70%] sm:w-[50%] xl:w-[25rem] 2xl:w-[30rem] 2xl:ml-20"
        />
        <div className="flex flex-col items-center 2xl:items-start gap-10 text-left p-10 2xl:p-20 max-w-5xl">
          <p className="text-center 2xl:text-left text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl max-w-screen-xl leading-[2.3rem] sm:leading-[2.7rem] md:leading-[3.5rem] lg:leading-[4.5rem] xl:leading-[5.2rem]">
            {`Because together we're stronger, let's whale together! 🐳`}
          </p>
          <a href={uniswapLink} target="_blank" rel="noreferrer">
            <Button className="bg-white font-bold text-black sm:mt-5 py-4 text-4xl px-10">{`Buy $WHALEZ`}</Button>
          </a>
        </div>
      </div>
    </Layout>
  );
}

export default RootPage;
