import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/moonlabs-icons/css/style.css";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import { trustWallet, ledgerWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { mainnet, goerli } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import Root from "./routes/Root";
import ErrorPage from "./error-page";
import { AuthProvider } from "./modules/auth/AuthContext";
import TwitterError from "./routes/error/twitter";
import Sale from "./routes/Sale";
import Rewards from "./routes/Rewards";

const { chains, provider, webSocketProvider } = configureChains(
  [
    ...(process.env.REACT_APP_ENABLE_TESTNETS === "true"
      ? [goerli]
      : [mainnet]),
  ],
  [
    // jsonRpcProvider({
    //   rpc: (chain) => ({http://localhost:3000/zcs
    //     http:
    //       process.env.REACT_APP_ENABLE_TESTNETS === "true"
    //         ? "https://testnet.era.zksync.dev"
    //         : "https://zksync2-mainnet.zksync.io",
    //   }),
    // }),
    publicProvider(),
  ],
);

const { wallets } = getDefaultWallets({
  appName: "WhaleTogether",
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "Other",
    wallets: [trustWallet({ chains }), ledgerWallet({ chains })],
  },
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/mint",
  //   element: <Sale />,
  // },
  // {
  //   path: "/mint/:referralAddress",
  //   element: <Sale />,
  // },
  // {
  //   path: "/rewards",
  //   element: <Rewards />,
  // },
  // {
  //   path: "/error/twitter",
  //   element: <TwitterError />,
  // },
]);

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        modalSize="compact"
        chains={chains}
        theme={lightTheme({
          accentColor: "#000",
          borderRadius: "none",
        })}
      >
        <AuthProvider>
          <RouterProvider router={router} />
          <Toaster />
        </AuthProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
