import { useState } from "react";
import Icon from "../shared/Icon";
import { DexToolsIcon, EtherscanIcon, TelegramIcon } from "../shared/Svgs";
import Button from "../shared/Button";

const TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
const TOKEN_PAIR_CONTRACT_ADDRESS =
  process.env.REACT_APP_TOKEN_PAIR_CONTRACT_ADDRESS;
const uniswapLink = `https://app.uniswap.org/#/swap?exactField=input&exactAmount=0.05&outputCurrency=${TOKEN_CONTRACT_ADDRESS}&inputCurrency=ETH`;
const dexToolsLink = `https://www.dextools.io/app/en/ether/pair-explorer/${TOKEN_PAIR_CONTRACT_ADDRESS}`;

export default function NavBar() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="relative">
        <div className="mx-auto px-4 sm:px-6 bg-black">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <span className="sr-only">zkTogether</span>
              <a href="/" className="my-auto">
                <img
                  src="/images/whalez-coin.png"
                  alt="WhaleTogether"
                  className="rounded-full cursor-pointer relative h-14 shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open menu</span>
                {/* Heroicon name: outline/menu */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 gap-7">
              <nav className="hidden md:flex space-x-3 text-white">
                <a
                  href="https://twitter.com/WhaleTogether"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="icon-whale-twitter"
                    className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                  />
                </a>
                <a
                  href="https://discord.gg/whaletogether"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="icon-discord"
                    className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                  />
                </a>
                <a
                  href="https://opensea.io/collection/whaletogether"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="icon-opensea"
                    className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                  />
                </a>
                <a
                  href={`https://etherscan.io/address/${TOKEN_CONTRACT_ADDRESS}`}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-1"
                >
                  <EtherscanIcon className="text-3xl relative hover:scale-110 transform transition duration-300 ease-in-out" />
                </a>
                <a
                  href="https://t.me/whalezcoin"
                  target="_blank"
                  rel="noreferrer"
                  className="pl-1 mt-0.5 text-white"
                >
                  <TelegramIcon className="relative fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />
                </a>
                <a
                  href={dexToolsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="pl-1 mt-1 text-white"
                >
                  <DexToolsIcon className="fill-current text-white hover:scale-110 transform transition duration-300 ease-in-out" />
                </a>
              </nav>

              <a href={uniswapLink} target="_blank" rel="noreferrer">
                <Button className="bg-gradient-primary text-white font-semibold drop-shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
                  BUY $WHALEZ
                </Button>
              </a>
            </div>
          </div>
        </div>

        <div
          className={
            open
              ? "opacity-100 scale-100 ease-out duration-200 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-[9999]"
              : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          }
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 ">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <a href="/" className="my-auto">
                    <img
                      src="/images/whalez-coin.png"
                      alt="zkTogether"
                      className="cursor-pointer relative h-14 left-2 shadow-lg hover:scale-105 transform transition duration-300 ease-in-out rounded-full"
                    />
                  </a>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setOpen(!open)}
                  >
                    <span className="sr-only">Close menu</span>
                    {/* Heroicon name: outline/x */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="flex flex-col gap-5">
                <div className="flex items-center justify-center gap-5">
                  <a
                    href="https://twitter.com/WhaleTogether"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="icon-whale-twitter"
                      className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                    />
                  </a>
                  <a
                    href="https://discord.gg/whaletogether"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="icon-discord"
                      className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                    />
                  </a>
                  <a
                    href="https://opensea.io/collection/whaletogether"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="icon-opensea"
                      className="text-3xl hover:scale-110 transform transition duration-300 ease-in-out"
                    />
                  </a>
                  <a
                    href={`https://etherscan.io/address/${TOKEN_CONTRACT_ADDRESS}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <EtherscanIcon className="text-3xl relative hover:scale-110 transform transition duration-300 ease-in-out" />
                  </a>
                  <a
                    href="https://t.me/whalezcoin"
                    target="_blank"
                    rel="noreferrer"
                    className="pl-1"
                  >
                    <TelegramIcon className="relative fill-current text-black hover:scale-110 transform transition duration-300 ease-in-out" />
                  </a>
                  <a
                    href={dexToolsLink}
                    target="_blank"
                    rel="noreferrer"
                    className="pl-1"
                  >
                    <DexToolsIcon className="fill-current text-black hover:scale-110 transform transition duration-300 ease-in-out" />
                  </a>
                </div>

                <a href={uniswapLink} target="_blank" rel="noreferrer">
                  <Button className="w-full bg-gradient-primary text-white font-semibold drop-shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
                    BUY $WHALEZ
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
